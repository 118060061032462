import React, {useState, useEffect} from 'react'
import MarijuanaIcon from '../../../assets/images/marijuana.png'
import { fetchBlogCategories } from '../../../services/api/MiscellaneousApi'

import './index.scss'

const BlogSidebar = ({onCategoryClick}) => {
    const [sidebarActive, setSidebarActive] = useState(false)
    const [blogCategories, setBlogCategories] = useState([]);
    const handleCategoryClick = (categoryId) =>{
        onCategoryClick(categoryId)
    }

    useEffect(() => {
        const getBlogCategories = async () => {
            try {
                const response = await fetchBlogCategories();
                const activeBlogCategories = response?.data?.data;
                setBlogCategories(activeBlogCategories);
            } catch (error) {
                console.error('An error occurred while fetching data:', error);
            }
        };

        getBlogCategories();
    }, []);

    const toggleSidebar = () =>{
        if(window.innerWidth < 992){
            setSidebarActive(!sidebarActive)
        }
    }
  return (
    <div className='blog-sidebar'>
        <div className='toggle-con d-lg-none mb-5'>
            <h6 className='fw-medium'>Blog filters</h6>
            <i className="bi bi-list text-theme-primary" onClick={()=> toggleSidebar()}></i>
        </div>
        <div className={`sidebar-content ${sidebarActive ? 'show' : ''}`}>
            <div className='sidebar-item'>
                <div className='item-title'>
                    <span className='title-icon'>
                        <img src={MarijuanaIcon} alt='Item Icon'/>
                    </span>
                    <h3>Categories</h3>
                </div>
                <div className='item-content'>
                    <ul className='categories-list'>
                        {blogCategories && blogCategories.length > 0 ? (
                            blogCategories.map(blogCategory => (
                                <li key={blogCategory.categoryId} onClick={()=> handleCategoryClick(blogCategory.categoryId)}>{blogCategory.categoryTitle}</li>
                            ))
                        ): (<h5>Nothing to show</h5>)}
                    </ul>
                </div>
            </div>
            <div className='sidebar-item d-none'>
                
                <div className='product-search-input'>
                    <input type='search' className='form-control rounded-pill' placeholder='Search post' />
                    <button className='btn btn-search btn-secondary rounded-pill hover-btn'>
                        <span>
                            <i className="bi bi-search"></i>
                        </span>
                    </button>
                </div>
            </div>
            <div className='sidebar-item mt-5 d-none'>
                <div className='item-title'>
                    <span className='title-icon'>
                        <img src={MarijuanaIcon} alt='Item Icon'/>
                    </span>
                    <h3>Tags</h3>
                </div>
                <div className='product-tags'>
                    <span className='product-tag'>Accessories</span>
                    <span className='product-tag'>Cannabis</span>
                    <span className='product-tag'>CBD</span>
                    <span className='product-tag'>Dispensary</span>
                </div>
            </div>

        </div>
    </div>
  )
}

export default BlogSidebar