import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import './index.scss'

// const useViewportHeight = () => {
//   const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

//   useEffect(() => {
//     const updateHeight = () => {
//       const adjustedHeight = window.innerHeight ;
//       setViewportHeight(adjustedHeight);
//     };
//     updateHeight();
//     window.addEventListener('resize', updateHeight);
//     return () => window.removeEventListener('resize', updateHeight);
//   }, []);

//   // alert(viewportHeight)
//   console.log(viewportHeight)

//   return viewportHeight;
// };

const Banner = () => {
  // const height = useViewportHeight();
  return (
    <div className='banner-section'>
        <div className='banner-content d-none'>
            <div className='banner-text text-center'>
                <h2 className='text-white'>THC CLUB HOUSTON<br/>BEST CANNABIS DISPENSARY IN HOUSTON TEXAS</h2>
                <h5 className='text-center text-white mt-4 col-11 col-md-8 col-xl-6 mx-auto'>
                  Choose from our wide variety of products from edibles, flowers. gmmies & pre-rolls
                </h5>
                <Link to="/about-us" className='btn btn-secondary rounded-pill mt-5'>Read More</Link>
            </div>
        </div>
    </div>
  )
}

export default Banner